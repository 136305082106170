import React, { useEffect, useRef, useLayoutEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import WheelPopUp from "./WheelPopUp";
import { fetchData } from "../api/fetchGameDataAPI";
import { AppDispatch } from "../store/store";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store/store";
import {
  setPreview,
  setShowWheelPopup,
  setSpinning,
  setSelected,
} from "../store/slice/CustomizeSlice";
import sampleImg from "../assets/SampleBackgroundImage.jpg";

const { REACT_APP_S3_URL } = process.env;

interface GameProps {
  isCutomize: boolean;
}

const Game: React.FC<GameProps> = ({ isCutomize }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const {
    segments,
    fontSize,
    fontFamily,
    backgroundColor,
    showWheelPopup,
    spinning,
    selected,
    backgroundIMG,
    preview,
    loader,
    logo,
    LogoUrl,
  } = useSelector((state: RootState) => state.customize);
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const gameId = queryParams.get("gameId") || sessionStorage.getItem("gameId");
  const [logoSize, setLogoSize] = useState({ width: 0, height: 0 });
  const isCustomisePage = location.pathname === "/";
  const bckImg = backgroundIMG || sessionStorage.getItem("backgroundImg");
  const GameRef = useRef<HTMLDivElement | null>(null);

  const handleSpin = () => {
    if (!spinning) {
      dispatch(setSpinning(true));
      // Ensure there are segments to spin
      if (segments.length === 0) {
        console.error("No segments to spin.");
        return;
      }
      const firstElement = segments[0];
      const reversedRest = segments.slice(1).reverse();
      const win = [firstElement, ...reversedRest];
      // Define the full rotation for visual effect and calculate the segment angle

      const segmentAngle = Math.ceil(360 / segments.length); // Angle per segment

      // Generate a random segment index to stop on
      const randomSegmentIndex = Math.ceil(
        Math.floor(Math.random() * segments.length),
      );

      const randomAngle = randomSegmentIndex * segmentAngle + 3600;

      let finalIndex = Math.floor((randomAngle - 3600) / segmentAngle);

      if (randomAngle === 3600) {
        finalIndex = 0;
      }
      dispatch(setSelected(win[finalIndex]));

      const canvas = canvasRef.current;
      if (canvas) {
        // Apply CSS transition to smoothly rotate the wheel
        canvas.style.transition = "transform 5s ease-out";
        canvas.style.transform = `rotate(${randomAngle}deg)`;

        // After the spin animation ends
        setTimeout(() => {
          // Reset styles to stop spinning
          canvas.style.animation = "none";
          canvas.style.transition = "none";
          canvas.style.transform = `none`;
          dispatch(setSpinning(false));
          if (preview || !isCustomisePage) dispatch(setShowWheelPopup(true));
        }, 6000); // Adjust timeout to match CSS animation duration
      }
    }
  };

  useLayoutEffect(() => {
    const updateLogoSize = () => {
      if (logo && GameRef.current) {
        const img = new Image();
        img.src = `${REACT_APP_S3_URL}/spin-the-wheel/logo/${logo}`;

        img.onload = () => {
          let screenHeight = GameRef.current?.offsetHeight || 0;
          let screenWidth = GameRef.current?.offsetWidth || 0;
          if (!isCustomisePage || preview) {
            screenHeight = window.innerHeight;
            screenWidth = window.innerWidth;
          }

          const aspectRatio = img.width / img.height;

          let logoHeight: number;
          let logoWidth: number;

          if (aspectRatio > 1.5) {
            const minHeightPercentage = 0.04;
            logoHeight = screenHeight * minHeightPercentage;
          } else {
            const maxHeightPercentage = 0.1;
            logoHeight = screenHeight * maxHeightPercentage;
          }

          logoWidth = logoHeight * aspectRatio;
          const maxWidth = screenWidth * 0.3;

          if (logoWidth > maxWidth) {
            logoWidth = maxWidth;
            logoHeight = maxWidth / aspectRatio;
          }

          setLogoSize({ width: logoWidth, height: logoHeight });
        };
      }
    };

    updateLogoSize();
  }, [logo, GameRef.current?.offsetHeight, GameRef.current?.offsetWidth]);

  useEffect(() => {
    if (!isCustomisePage && gameId) {
      dispatch(fetchData(gameId));
    }
  }, [isCustomisePage, logo]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    if (GameRef) {
      let screenHeight = GameRef.current?.offsetHeight || 0;
      let screenWidth = GameRef.current?.offsetWidth || 0;

      if (!isCustomisePage || preview) {
        screenHeight = window.innerHeight;
        screenWidth = window.innerWidth;
      }

      const size = Math.min(screenHeight, screenWidth) * 0.65;

      canvas.width = size;
      canvas.height = size;
      if (size < 260) {
        canvas.width = 290;
        canvas.height = 290;
      }
    }

    if (!preview && isCustomisePage) {
      dispatch(setShowWheelPopup(false));
    }

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const totalWeightage = segments.reduce(
      (total, segment) => total + segment.weight,
      0,
    );

    let difftoAdd = 0.09;
    if (segments.length > 9) {
      difftoAdd = 0.05;
    }

    const defaultSegments = 6;
    let newStartAnglediff = (segments.length - defaultSegments) * difftoAdd;

    let startAngle = 10.47 + newStartAnglediff;

    for (const segment of segments) {
      const angle = (segment.weight / totalWeightage) * (2 * Math.PI);

      // Calculate segment center
      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;
      const maxDimension = Math.min(canvas.width, canvas.height);
      const radius = (maxDimension / 2) * 1.1; // Use 80% of the canvas dimension as radiusthe radius doesn't exceed canvas dimensions
      const segmentAngle = startAngle + angle / 2;

      // Calculate text position
      const textX = centerX + Math.cos(segmentAngle) * (radius / 2);
      const textY = centerY + Math.sin(segmentAngle) * (radius / 2);
      ctx.lineWidth = 0;
      ctx.lineTo(centerX, centerY);

      // Draw segment

      ctx.beginPath();
      ctx.moveTo(centerX, centerY);
      ctx.arc(centerX, centerY, radius, startAngle, startAngle + angle);
      ctx.fillStyle = segment.color;
      ctx.fill();
      // ctx.stroke();

      // Write text inside the segment with rotation
      const text = segment.prize; // Adjust as needed

      ctx.save(); // Save the current transformation state
      ctx.translate(textX, textY); // Translate the origin to the text position
      ctx.rotate(segmentAngle + 0); // Rotate the context to match the segment angle

      ctx.font = ` ${fontSize}px ${fontFamily} `;
      ctx.textAlign = "center";
      ctx.fillStyle = segment.textColor;
      ctx.fillText(text, 12, 6); // Draw the text at the translated position (0, 0)
      ctx.restore(); // Restore the transformation state to prevent the rotation from affecting subsequent drawings

      startAngle += angle;
    }
  }, [
    segments,
    fontSize,
    fontFamily,
    location.pathname,
    bckImg,
    backgroundColor,
    logo,
    GameRef.current?.offsetHeight,
    preview,
  ]);

  return (
    <div
      ref={GameRef}
      style={{
        backgroundImage:
          backgroundIMG !== "default" && backgroundIMG !== "none"
            ? `url(${REACT_APP_S3_URL}/spin-the-wheel/background/${backgroundIMG})`
            : backgroundIMG !== "none"
              ? `url(${sampleImg})`
              : "none",
        backgroundSize: "cover",
        backgroundColor: backgroundColor,
        backgroundRepeat: "no-repeat",
      }}
      className={`flex flex-col items-center justify-center ${
        !isCustomisePage || preview
          ? "m-0 h-screen w-screen overflow-hidden border-0 p-0"
          : "h-[100vh] overflow-hidden rounded-xl"
      } border border-secondary`}
    >
      {!loader && (
        <div className="flex flex-col items-center justify-center">
          {logo && (
            <div className="mb-4 flex items-center justify-center">
              {LogoUrl ? (
                <a href={LogoUrl} target="_blank" rel="noopener noreferrer">
                  <img
                    loading="lazy"
                    src={`${REACT_APP_S3_URL}/spin-the-wheel/logo/${logo}`}
                    alt="Logo"
                    style={{
                      width: `${logoSize.width}px`,
                      height: `${logoSize.height}px`,
                    }}
                  />
                </a>
              ) : (
                <img
                  loading="lazy"
                  src={`${REACT_APP_S3_URL}/spin-the-wheel/logo/${logo}`}
                  alt="Logo"
                  style={{
                    width: `${logoSize.width}px`,
                    height: `${logoSize.height}px`,
                  }}
                />
              )}
            </div>
          )}
          <div className="WheelShadow flex flex-col items-center justify-center">
            <canvas
              id="Wheel"
              className="rounded-full border-8 border-black bg-black"
              ref={canvasRef}
            />

            <button
              onClick={handleSpin}
              disabled={spinning}
              className="absolute transform cursor-pointer rounded-full p-0"
            >
              <svg
                className="md:h-18 md:w-18 h-16 w-16 lg:h-24 lg:w-24"
                viewBox="-20 -40 40 80"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className=""
                  d="M -8.513980260046061 -23.200596199721776
                   L 0 -38.31291112119587
                   L 8.513980260046061 -23.200596199721776
                   A 25.598076923076924 25.598076923076924 0 1 1 -8.513980260046061 -23.200596199721776
                   Z"
                  fill="black"
                ></path>
                <text
                  fontSize={`${fontSize}px`}
                  fontFamily={`${fontFamily}`}
                  fill="white"
                  text-anchor="middle"
                  y="6.655500000000001"
                  pointer-events="none"
                >
                  Spin
                </text>
              </svg>
            </button>
          </div>
          {preview && (
            <button
              data-tooltip-id="preview"
              data-tooltip-content="Preview"
              onClick={() => {
                dispatch(setSpinning(false));
                dispatch(setPreview(!preview));
              }}
              className="absolute bottom-14 rounded-full bg-black px-4 py-2 text-center text-white hover:scale-105 hover:opacity-80"
            >
              {!preview ? "Preview" : "Exit preview"}
            </button>
          )}
          {showWheelPopup && <WheelPopUp segment={selected} />}
        </div>
      )}
    </div>
  );
};

export default Game;
