import { useCallback, useRef, useState } from "react";

import { FaChevronDown } from "react-icons/fa";

import { uploadBackgroundImage } from "../api/uploadBackgroundImageAPI";
import { useDropzone } from "react-dropzone";
import { Plus, Trash, Minus } from "react-feather";
import CustomOptions from "./CustomOptions";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store/store";
import {
  setFontSize,
  setFontFamily,
  setBackgroundImg,
  setBackgroundColor,
  setTextColor,
  setSecondaryColor,
  setStyle,
} from "../store/slice/CustomizeSlice";
import sampleImg from "../assets/SampleBackgroundImage.jpg";

const { REACT_APP_S3_URL } = process.env;

interface DesignProps {}

const fontOptions = [
  { name: "Arial", value: "Arial, sans-serif" },
  { name: "Times New Roman", value: "Times New Roman, serif" },
  { name: "Courier New", value: "Courier New, monospace" },
  { name: "Verdana", value: "Verdana, sans-serif" },
  { name: "Georgia", value: "Georgia, serif" },
  {
    name: "Palatino Linotype",
    value: '"Palatino Linotype", "Book Antiqua", Palatino, serif',
  },
  { name: "Tahoma", value: "Tahoma, Geneva, sans-serif" },
  { name: "Trebuchet MS", value: '"Trebuchet MS", Helvetica, sans-serif' },
  { name: "Arial Black", value: '"Arial Black", Gadget, sans-serif' },
  { name: "Comic Sans MS", value: '"Comic Sans MS", cursive, sans-serif' },
  { name: "Impact", value: "Impact, Charcoal, sans-serif" },
  {
    name: "Lucida Sans Unicode",
    value: '"Lucida Sans Unicode", "Lucida Grande", sans-serif',
  },
  { name: "Lucida Console", value: '"Lucida Console", Monaco, monospace' },
  { name: "Garamond", value: "Garamond, serif" },
  { name: "MS Sans Serif", value: '"MS Sans Serif", Geneva, sans-serif' },
];

const colorPalette = ["#f94b26", "#5ecde1", "#f2e6b6", "#f8b214"];

const Design: React.FC<DesignProps> = ({}) => {
  const {
    fontSize,
    fontFamily,
    backgroundColor,
    backgroundIMG,
    textColor,
    secondaryColor,
  } = useSelector((state: RootState) => state.customize);
  const dispatch = useDispatch();
  const [chooseBackground, setChooseBackground] = useState(
    backgroundIMG === "none" ? 0 : 1,
  );
  const backgroundColorRef = useRef<HTMLInputElement>(null);
  const textColorRef = useRef<HTMLInputElement>(null);
  const secondaryColorRef = useRef<HTMLInputElement>(null);
  const imageURL = `${REACT_APP_S3_URL}/spin-the-wheel/background/${backgroundIMG}`;

  const handleBackgroundColorChange = (newColor: string) => {
    sessionStorage.removeItem("backgroundImg");
    dispatch(setBackgroundColor(newColor));
    handleDeleteImage();
    dispatch(setBackgroundImg("none"));
  };

  const handleDeleteImage = () => {
    dispatch(setBackgroundImg("default"));
  };

  const handleClicktextColor = () => {
    if (textColorRef.current) {
      textColorRef.current.click();
    }
  };

  const handlesecondaryColorClick = () => {
    if (secondaryColorRef.current) {
      secondaryColorRef.current.click();
    }
  };
  const handleClick = () => {
    if (backgroundColorRef.current) {
      backgroundColorRef.current.click();
    }
  };

  const updateImage = async (file: any): Promise<void> => {
    const reader = new FileReader();
    reader.onloadend = function () {
      const result = reader.result as string;
      const image = new Image();
      image.src = result;

      image.onload = () => {
        const width = image.width;
        const height = image.height;
        const MIN_WIDTH = 1366;
        const MIN_HEIGHT = 768;
        const MAX_WIDTH = 1920;
        const MAX_HEIGHT = 1080;
        const MAX_FILE_SIZE_MB = 2;

        // Perform any necessary validations here based on the image dimensions
      };
    };

    const formData = new FormData();

    if (file) {
      reader.readAsDataURL(file);
      formData.append("file", file);

      try {
        const fileName = await uploadBackgroundImage(file);

        if (fileName) {
          dispatch(setBackgroundImg(fileName));
        } else {
          console.log("Failed to upload background image.");
        }
      } catch (error) {
        console.error("Error uploading background image:", error);
      }
    } else {
      console.error("No file selected.");
      document.body.style.backgroundImage = "";
    }
  };

  const onDropBackgroundImage = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const result = e.target?.result as string;

        updateImage(file);
      };
      reader.readAsDataURL(file);
    }
  }, []);

  const {
    getRootProps: getRootPropsBackground,
    getInputProps: getInputPropsBackground,
  } = useDropzone({
    onDrop: onDropBackgroundImage,
    accept: { "image/*": [] },
    multiple: false,
  });

  return (
    <div className="flex flex-col pb-[5rem] xl:pb-5">
      <div className="design">
        <label className="block text-base font-semibold">
          Choose your background
        </label>
        <div className="mb-2 mt-4 block">
          <label className="mr-6 flex cursor-pointer items-center">
            <input
              type="radio"
              name="backgroundType"
              value="colorPalette"
              checked={chooseBackground === 0}
              onChange={() => {
                dispatch(setBackgroundColor(backgroundColor));
                dispatch(setBackgroundImg("none"));
                setChooseBackground(0);
              }}
              className="mr-2 scale-125 transform cursor-pointer"
            />
            Color palette
          </label>
        </div>
        <div className="mb-6 mt-4 block">
          <label className="flex cursor-pointer items-center">
            <input
              type="radio"
              name="backgroundType"
              value="uploadImage"
              checked={chooseBackground === 1}
              onChange={() => {
                dispatch(setBackgroundImg("default"));
                setChooseBackground(1);
              }}
              className="mr-2 scale-125 transform cursor-pointer"
            />
            Upload background image
          </label>
        </div>

        <div className="h-2 w-full border-b border-[#dadde1]"></div>
        <div className="my-2 flex w-auto flex-col items-start gap-x-2 gap-y-2">
          <h3 className="mb-1 mt-6 text-base font-semibold">Text </h3>
          <div className="flex w-full flex-col items-start justify-start gap-x-1 gap-y-3 rounded-lg">
            <div className="flex w-full flex-row justify-between">
              <label className="block text-base font-medium">
                Text color <br />
              </label>
              <div
                onClick={handleClicktextColor}
                className="w-18 mb-1 relative flex h-8 cursor-pointer items-center rounded-md border border-[#dadde1] p-1"
              >
                <div
                  className="h-6 w-6 rounded-md border border-[#dadde1] p-1"
                  style={{
                    backgroundColor: textColor,
                  }}
                  onClick={(e) => e.preventDefault()}
                />
                <FaChevronDown className="ml-2 h-3 text-gray-400" />
                <input
                  id="textColor"
                  type="color"
                  ref={textColorRef}
                  className="absolut invisible rounded-lg"
                  onChange={(e) => dispatch(setTextColor(e.target.value))}
                />
              </div>
            </div>
            <div className="flex w-full items-center justify-between">
              <label className="block text-base font-medium">
                Text Size <br />
              </label>
              <div className="flex items-center rounded-md border border-[#dadde1]">
                <button
                  onClick={() => {
                    const newSize = parseInt(fontSize) + 1; // Convert current fontSize string to number, increment by 1
                    dispatch(setFontSize(newSize.toString()));
                  }}
                  className={`${
                    parseInt(fontSize) >= 22
                      ? "cursor-not-allowed bg-gray-100"
                      : ""
                  } p-2 hover:opacity-60`}
                  disabled={parseInt(fontSize) >= 22}
                >
                  <Plus className="h-4 w-4" />
                </button>
                <div className="h-8 border-l border-[#dadde1]"></div>
                <button
                  onClick={() => {
                    const newSize = parseInt(fontSize) - 1; // Convert current fontSize string to number, increment by 1
                    dispatch(setFontSize(newSize.toString()));
                  }}
                  className={`${
                    parseInt(fontSize) <= 12
                      ? "cursor-not-allowed bg-gray-100"
                      : ""
                  } p-2 hover:opacity-60`}
                  disabled={parseInt(fontSize) <= 12}
                >
                  <Minus className="h-4 w-4" />
                </button>
              </div>
            </div>

            <div className="my-1 flex w-full flex-row items-center justify-between">
              <label htmlFor="font family" className="block font-medium">
                Font
              </label>

              <CustomOptions
                options={fontOptions}
                value={fontFamily}
                onChange={(e) => dispatch(setFontFamily(e))}
              />
            </div>
          </div>
        </div>
        <div className="mb-6 h-2 w-full border-b border-[#dadde1]"></div>
        <h3 className="text-base font-semibold">Background </h3>

        {chooseBackground === 0 && (
          <div className="my-4 flex w-full flex-row items-start justify-between">
            <label className="block font-medium">Background Color</label>
            {backgroundIMG === "none" && (
              <div className="flex flex-row items-center justify-center gap-x-2">
                {colorPalette.map((color, index) => (
                  <div
                    key={index}
                    className={`flex h-6 w-6 cursor-pointer flex-row gap-x-2 rounded-md shadow-inner ${
                      color === backgroundColor
                        ? "h-7 w-7 border-2 border-blue-600"
                        : ""
                    }`}
                    style={{ backgroundColor: color }}
                    onClick={() => {
                      dispatch(setBackgroundColor(color));
                      dispatch(setBackgroundImg("none"));
                    }}
                  />
                ))}
                <div
                  className="flex h-6 w-6 cursor-not-allowed items-center justify-center rounded-md border border-[#dadde1]"
                  style={{ cursor: "not-allowed" }}
                >
                  <Plus className="h-4 w-4" />
                </div>
              </div>
            )}

            {/* <div
              onClick={handleClick}
              className="relative flex items-center rounded-md border border-[#dadde1] p-1"
            >
              <div
                className="h-6 w-6 cursor-pointer rounded-md border border-[#dadde1]"
                style={{
                  backgroundColor: backgroundColor,
                }}
                onClick={(e) => e.preventDefault()}
              />
              <FaChevronDown className="ml-2 h-3 text-gray-400" />
            </div> */}

            {/* <input
              id="backgroundColor"
              type="color"
              ref={backgroundColorRef}
              className="absolut invisible rounded-lg"
              onChange={(e) => handleBackgroundColorChange(e.target.value)}
            /> */}
          </div>
        )}
        {backgroundIMG !== "none" && (
          <div className="my-4 flex w-full flex-row items-start justify-between">
            <label className="block font-medium">
              Primary Color <br />
            </label>
            <div
              onClick={handleClick}
              className="relative flex items-center rounded-md border border-[#dadde1] p-1"
            >
              <div
                className="h-6 w-6 cursor-pointer rounded-md border border-[#dadde1]"
                style={{
                  backgroundColor: backgroundColor,
                }}
                onClick={(e) => e.preventDefault()}
              />
              <FaChevronDown className="ml-2 h-3 text-gray-400" />
            </div>

            <input
              id="backgroundColor"
              type="color"
              ref={backgroundColorRef}
              className="absolut invisible rounded-lg"
              onChange={(e) => {
                dispatch(setBackgroundColor(e.target.value));
              }}
            />
          </div>
        )}
        <div className="flex w-full my-3 flex-row items-start justify-between">
          <label className="block font-medium">
            Secondary Color <br />
          </label>
          <div
            onClick={handlesecondaryColorClick}
            className="relative flex items-center rounded-md border border-[#dadde1] p-1"
          >
            <div
              className="h-6 w-6 cursor-pointer rounded-md border border-[#dadde1]"
              style={{
                backgroundColor: secondaryColor,
              }}
              onClick={(e) => e.preventDefault()}
            />
            <FaChevronDown className="ml-2 h-3 text-gray-400" />
          </div>

          <input
            id="secondaryColorRef"
            type="color"
            ref={secondaryColorRef}
            className="absolut invisible rounded-lg"
            onChange={(e) => {
              dispatch(setSecondaryColor(e.target.value));
            }}
          />
        </div>

        {chooseBackground === 1 && (
          <div>
            <div>
              <h3 className="block font-medium"> Image</h3>
              <p className="text-[13px] text-gray-500">
                We support png, gif, jpg, and svg
              </p>
              {backgroundIMG !== "none" ? (
                <div className="mb-3 mt-6 flex items-center justify-between space-x-4 rounded-md border border-[#dadde1] bg-[#f5f4f7] p-4 text-center hover:border-primary">
                  <img
                    src={backgroundIMG === "default" ? sampleImg : imageURL}
                    alt="Uploaded"
                    className="h-20 w-20 rounded-md border border-[#dadde1]"
                  />

                  <button
                    className="flex items-center rounded-md text-base font-medium text-primary underline"
                    {...getRootPropsBackground()}
                  >
                    Update image
                  </button>
                  <button
                    disabled={backgroundIMG === "default"}
                    className="flex items-center text-primary"
                    onClick={handleDeleteImage}
                  >
                    <Trash className="mr-2 h-5" />
                  </button>

                  <input {...getInputPropsBackground()} />
                </div>
              ) : (
                <div
                  {...getRootPropsBackground()}
                  className="mb-3 mt-6 flex cursor-pointer items-center justify-start rounded-md border border-[#dadde1] p-6 text-center hover:border-primary"
                >
                  <input {...getInputPropsBackground()} />
                  <div className="flex items-center justify-start">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      role="img"
                      preserveAspectRatio="xMidYMid meet"
                      width="64"
                      height="64"
                      viewBox="0 0 48 48"
                      className="ml-10"
                    >
                      <g color="inherit">
                        <g
                          style={{
                            opacity: "var(--dropzone-icon-opacity, .5)",
                          }}
                        >
                          <circle
                            cx="27.84"
                            cy="15.36"
                            r="3.84"
                            style={{
                              transition: "all 0.3s ease 0s",
                              transform:
                                "var(--dropzone-icon-primary-transform, none)",
                              fill: "var(--dropzone-icon-primary, #161617)",
                            }}
                          ></circle>
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M26.3553 27.7139L19.92 19.44L8.16 34.56L21.12 34.56L31.68 34.56L39.84 34.56L30.48 22.32L26.3553 27.7139Z"
                            style={{
                              transition: "all 0.3s ease 0s",
                              fill: "var(--dropzone-icon-secondary, rgba(0, 0, 0, 0.55))",
                            }}
                          ></path>
                        </g>
                      </g>
                    </svg>
                    <p className="w-40 text-primary">
                      Drag and drop or{" "}
                      <span className="cursor-pointer text-black underline">
                        Click to add image
                      </span>
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Design;
